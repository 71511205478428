/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "@/axios.js"

const state = {
  scrollY: 0,
  keyword: null,
  switchState: 0,
  userInfo: null,
  customerInfo: null,
  snackBar: {},
  activeFile: null,
  audioFile: null,
  scrollTop: false,
  webVersion: '1.14.34',
  baseURL : axios.defaults.baseURL,
  innerWidth: 1920,
  isMobile: false,
  notchHeight: {
    'top': 0,
    'bottom': 0,
  },


  paymentScrollY: 0,
  paymentType: '', // type: a, b
  paymentUserName: '',

  regionList: [
    '전체',
    '서울특별시',
    '경기도', 
    '인천광역시',
    '부산광역시',
    '대구광역시',
    '광주광역시',
    '대전광역시',
    '울산광역시',
    '세종특별자치시',
    '강원도',
    '경상남도',
    '경상북도',
    '충청남도',
    '충청북도',
    '전라남도',
    '전라북도',
    '제주특별자치도',
  ],
  userLevelList: [
    {
      name: '권한 없음',
      background: '#FFB200',
      level: 0,
    },{
      name: 'AI검토',
      background: 'rgb(255, 92, 16)',
      level: 1,
    },{
      name: '선생님(유형)',
      background: '#4339F2',
      level: 2,
    },{
      name: '검토 및 분류',
      background: 'rgb(247, 16, 255)',
      level: 3,
    },{
      name: '관리자(외)',
      background: '#1D9CD3',
      level: 4,
    },{
      name: '관리자(내)',
      background: 'rgb(255, 16, 116)',
      level: 5,
    },{
      name: '마스터',
      background: 'rgb(16, 255, 215)',
      level: 10,
    }
  ]
}
export default state 
